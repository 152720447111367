<template>
    <div>
        <div style="margin-bottom: 30px;font-size: 20px;font-weight: bold;">My Orders</div>
        <div class="order">
            <!-- 我的订单主要内容 -->
            <div class="order-content">
                <div class="content">
                    <div class="tit">
                        <div style="display: flex;margin-left: 29px;">
                            <div class="Tab" @click="changeTab1('')" :class="{ 'active1': currentTab1 === '' }">ALL
                            </div>
                            <div class="Tab" @click="changeTab1(2)" :class="{ 'active1': currentTab1 === 2 }">
                                DELIVERED</div>
                            <div class="Tab" @click="changeTab1(1)" :class="{ 'active1': currentTab1 === 1 }">
                                SHIPPED</div>
                            <div class="Tab" @click="changeTab1(0)" :class="{ 'active1': currentTab1 === 0 }">
                                PENDING</div>
                        </div>
                        <div style="display: flex;align-items: center;">
                            <div style="position: relative;">
                                <input type="text" v-model="sousuo" class="int" placeholder="Enter order no."
                                    @input="sousuoBtn">
                                <img src="../../assets/imgs/uploads/Group 104.png" alt=""
                                    style="position: absolute;width: 29px;height: 29px;right: 2%;top: 11%;">
                            </div>
                            <div>
                                <button class="export" @click="export1()">
                                    <img src="../../assets/imgs/uploads/ion_share-outline.png" alt=""
                                        style="width: 15px;height: 15px;"> Export to Excel</button>
                            </div>
                        </div>
                    </div>
                    <table class="centered-table" v-if="orders.length > 0">
                        <thead>
                            <tr style="height: 60px;">
                                <th>{{ $t('order.status') }}</th>
                                <th>{{ $t('order.ordernum') }}</th>
                                <th>Order Time</th>
                                <th>{{ $t('order.amount') }}</th>
                                <th>{{ $t('order.sum') }}</th>
                                <th>Shipping</th>
                            </tr>
                        </thead>
                        <tbody v-for="(item, index) in orders" :key="index">
                            <tr>
                                <td>
                                    <div class="stus" :style="getOrderStyle(item)">{{ getStatusText(item.status) }}
                                    </div>
                                </td>
                                <td><span @click="showSub(item.id)" style="cursor: pointer;">{{ item.orderNo }}</span></td>
                                <td>{{ item.createTime | dateFormat }}</td>
                                <td>{{ item.totalQty }}</td>
                                <td>${{ item.totalAmount }}</td>
                                <td v-if="item.status == 1">
                                    <span style="font-size: 12px;text-decoration: underline;cursor: pointer;">Track
                                        Shipment ></span>
                                </td>
                                <td v-else></td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="order-empty">
                        <div style="display: flex;justify-content: center;align-items: center;margin: 60px auto;">
                            <div style="font-size: 12px;color: #B3B3B3;">No Orders Yet</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {saveAs} from "file-saver"
export default {
    data() {
        return {
            currentTab1: '',
            currentType: 0,
            orders: [],// 订单列表
            sousuo: ''
        }
    },
    mounted() {
        this.activated()
    },
    methods: {
        sousuoBtn() {
            this.$axios
                .post("/api/mall/order/getPageList", {
                    memberId: this.$store.getters.getUser.id,
                    status: this.currentType,
                    orderNo: this.sousuo
                })
                .then(res => {
                    if (res.data.code === 200) {
                        this.orders = res.data.data;
                    } else {
                        this.notifyError(res.data.message);
                    }
                    // console.log(this.orders)
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        changeTab1(index) {
            this.currentTab1 = index;
            this.currentType = index;
            this.$axios
                .post("/api/mall/order/getPageList", {
                    memberId: this.$store.getters.getUser.id,
                    status: this.currentType,
                })
                .then(res => {
                    if (res.data.code === 200) {
                        this.orders = res.data.data;
                    } else {
                        this.notifyError(res.data.message);
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        showSub(id) {
            this.$router.push({
                path: '/Personalcenter/Ordersub',
                query: {
                    id: id
                }
            })
        },
        getStatusText(status) {
            switch (status) {
                case 0:
                    return 'Pending';
                case 1:
                    return 'processing';
                case 2:
                    return 'delivering';
                default:
                    return 'null';
            }
        },
        getOrderStyle(item) {
            return {
                color: this.getBorderColor(item.status),
                backgroundColor: this.getStatusColor(item.status),
                borderColor: this.getBorderColor(item.status),
            };
        },
        getStatusColor(status) {
            switch (status) {
                case 0:
                    return '#EDE2FF';
                case 1:
                    return '#FFF5CE';
                case 2:
                    return '#D9D9D9';
                default:
                    return 'white';
            }
        },
        getBorderColor(status) {
            switch (status) {
                case 0:
                    return '#8A43FF';
                case 1:
                    return '#FFB800';
                case 2:
                    return '#959595';
                default:
                    return 'black';
            }
        },

        activated() {
            // 获取订单数据
            this.$axios
                .post("/api/mall/order/getPageList", {
                    memberId: this.$store.getters.getUser.id,
                })
                .then(res => {
                    if (res.data.code === 200) {
                        this.orders = res.data.data;
                    } else {
                        this.notifyError(res.data.message);
                    }
                    // console.log(this.orders)
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        export1(){
            this.$axios
            .post("/api/mall/order/getExportList", {
                memberId: this.$store.getters.getUser.id,
                status: this.currentType,
                orderNo: this.sousuo
            },{ responseType: "blob" })
            .then(res => {
                const obj = res.data
                saveAs(obj , '订单.xlsx')
            })
        }
    },

}
</script>

<style scoped>
.content {
    border: 1px solid #D9D9D9;
    border-radius: 15px;
}

.tit {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
}

.tit .Tab {
    width: 116px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #959595;
    cursor: pointer;
}

.active1 {
    font-weight: bold;
    color: #000 !important;
    border-bottom: 5px solid #000;
}

.tit .int {
    width: 236px;
    height: 37px;
    border: 1px solid #959595;
    border-radius: 12px;
    padding-left: 19px;
}

.tit .export {
    width: 128px;
    height: 36px;
    margin-right: 15px;
    margin-left: 22px;
    border: 1px solid #FFA9A4;
    border-radius: 5px;
    background-color: #FFE7E5;
    color: #CA141D;
    cursor: pointer;
}

.content .centered-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.centered-table .stus {
    width: 83px;
    margin: 0 auto;
    height: 22px;
    font-size: 14px;
    border-radius: 23px;
    border: 1px solid #959595;
}

.content th {
    border-bottom: 1px solid #D9D9D9;
    padding: 8px;
    text-align: center;
}

.content td {
    border-bottom: 1px solid #D9D9D9;
    padding: 8px;
    text-align: center;
    height: 86px;
}

/*我的订单结束*/
</style>